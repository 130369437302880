import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { motion, useScroll, useTransform } from "framer-motion";
import loadable from "@loadable/component";

const isBrowser = typeof window !== "undefined";

const PaintingComponent = () => {
  const { scrollYProgress } = useScroll();
  const translateY = useTransform(scrollYProgress, [0, 1], [0, -1000], {
    clamp: true,
  });

  return (
    <motion.div
      className="w-[135px] md:w-[240px] relative z-20"
      style={{ translateY: translateY }}
    >
      <StaticImage
        src="../images/the-crew/painting.jpg"
        alt={"Person holding painting of a sailor"}
      />
    </motion.div>
  );
};

const LoadablePainting = loadable(() =>
  isBrowser ? Promise.resolve(PaintingComponent) : Promise.resolve(() => null)
);

export default function Painting() {
  return (
    <>
      <LoadablePainting />
    </>
  );
}
