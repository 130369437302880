import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Header from "../components/Header";
import MainLayout from "../components/MainLayout";
import Seo from "../components/Head";
import CrewSlider from "../components/CrewSlider";
import DogSlider from "../components/DogSlider";
import Prefooter from "../components/Prefooter";
import Heading from "../components/Heading";
import { H2, H4, Paragraph } from "../components/Utilities";
import Salt from "../components/Salt";

import Painting from "../components/PaintingImage";
import Beach from "../images/the-crew/beach.jpg";

const TheCrew = () => {
  
  const showDogSlider = () => {
    const dogSlider = document.getElementById("office-dogs");
    if (dogSlider.classList.contains("opacity-0")) {
      dogSlider.classList.remove("opacity-0", "h-0", "hidden");
      dogSlider.classList.add("lg:h-[800px]");
    } else {
      dogSlider.classList.add("opacity-0", "h-0", "hidden");
      dogSlider.classList.remove("lg:h-[800px]");
    }
  };

  return (
    <StaticQuery
      query={graphql`
        {
          allWpPage(filter: { title: { eq: "The Crew" } }) {
            edges {
              node {
                template {
                  ... on WpDefaultTemplate {
                    footerContent {
                      footerTitle
                      footerDescription
                      footerBackground {
                        altText
                        sourceUrl
                      }
                    }
                  }
                }
                crew {
                  heroDescription
                  heroSalt
                  heroTitle
                  numbersDescription
                  numbersSubtitle
                  numbersTitle
                  metaTitle
                  metaDescription
                  number {
                    statistic
                    title
                    details
                  }
                  department {
                    description
                    title
                    crewMembers {
                      biography
                      jobTitle
                      name
                      yearsOfService
                      largeProfile {
                        altText
                        sourceUrl
                      }
                      questions {
                        question
                        response
                      }
                      smallProfile {
                        altText
                        sourceUrl
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(props) => (
        <MainLayout>
          <Header />
          <Seo
            meta_title={props.allWpPage.edges[0].node.crew.metaTitle}
            meta_title_backup={props.allWpPage.edges[0].node.crew.heroTitle}
            meta_description={
              props.allWpPage.edges[0].node.crew.metaDescription
            }
          />
          <section
            className="lg:pt-[65px] max-w-screen-2xl mx-auto relative z-10 overflow-hidden"
            id="hero"
          >
            <div className="grid md:grid-cols-6 relative z-10 md:pl-28">
              <div className="col-span-4">
                <div className="bg-iron px-8 md:px-10 py-32 lg:pt-24 md:pb-32 lg:px-24">
                  <Heading
                    text={props.allWpPage.edges[0].node.crew.heroTitle}
                    alignment={"justify-start"}
                  />
                  <Paragraph
                    content={props.allWpPage.edges[0].node.crew.heroDescription}
                    classes={"text-smoke mt-7"}
                  />
                </div>
              </div>
            </div>
            <div className="max-w-screen-xl mx-auto relative z-10 h-[600px] sm:h-[700px] md:h-[600px]">
              <div className="absolute -top-5 lg:-top-32 left-0">
                <div className="grid grid-cols-2 md:grid-cols-3 relative z-10">
                  <div className="translate-y-5 md:translate-y-28 w-60 md:w-full">
                    <StaticImage
                      src="../images/the-crew/bonfire.jpg"
                      alt="Bonfire at beach"
                    />
                  </div>
                  <div className="md:col-span-2 grid md:grid-cols-2 items-end md:-translate-x-20 relative">
                    <StaticImage
                      src="../images/the-crew/dwayne.jpg"
                      alt="Dwayne checking out the boats"
                      className="w-[225px] sm:w-[350px] -ml-5 sm:ml-auto -translate-y-10 md:translate-y-0"
                    />
                    <StaticImage
                      src="../images/the-crew/tim-emma.jpg"
                      alt="Emma and tim in conference room"
                      className="-translate-x-4 md:translate-x-5 translate-y-10 md:translate-y-0"
                    />
                    <div className="absolute bottom-40 sm:bottom-52 md:-bottom-14 md:left-0 -translate-x-20 md:translate-x-0">
                      <Salt
                        title={props.allWpPage.edges[0].node.crew.heroSalt}
                        range={[0, 0.1]}
                        classes={"w-[250px] md:w-full text-center"}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3">
                  <div className="hidden md:block"></div>
                  <StaticImage
                    src="../images/the-crew/ship.jpg"
                    alt={"Illustration of ship crashing into beach"}
                    className="translate-y-10 md:translate-y-0 translate-x-5 md:translate-x-0 w-[225px] md:w-[340px] ml-auto"
                  />
                  <Painting />
                </div>
              </div>
            </div>
            <img
              src={Beach}
              alt="Beach with tide"
              className="absolute bottom-52 md:bottom-0 left-0 w-[700px] z-0"
            />
          </section>

          <section id="leadership" className="relative">
            <div className="bg-bronze relative z-10">
              <div className="max-w-screen-xl mx-auto grid md:grid-cols-2 px-8 pt-20 pb-32 md:pb-40 md:px-20">
                <H2
                  title={props.allWpPage.edges[0].node.crew.department[0].title}
                  classes={"mb-4 lg:mb-0"}
                />
                <Paragraph
                  content={
                    props.allWpPage.edges[0].node.crew.department[0].description
                  }
                  classes={"text-ink"}
                />
              </div>
            </div>
            <div className="max-w-screen-xl mx-auto">
              <CrewSlider
                data={{
                  container_classes:
                    "mt-12 md:mt-20 max-w-screen-sm mx-auto xl:mx-0 xl:ml-auto",
                  slider_classes:
                    "relative z-20 -translate-y-28 md:-translate-y-40 px-4 md:px-0",
                  crew_members:
                    props.allWpPage.edges[0].node.crew.department[0]
                      .crewMembers,
                  slides_to_show: 2,
                  arrow_type: "none",
                  response: [
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: false,
                      },
                    },
                  ],
                }}
              />
            </div>
          </section>

          <section id="marketing-account-management">
            <div className="max-w-screen-xl mx-auto mt-5 grid md:grid-cols-2 px-8 pb-14 md:px-20">
              <H2
                title={props.allWpPage.edges[0].node.crew.department[1].title}
                classes={"mb-4 lg:mb-0"}
              />
              <Paragraph
                content={
                  props.allWpPage.edges[0].node.crew.department[1].description
                }
                classes={"text-smoke"}
              />
            </div>
            <CrewSlider
              data={{
                container_classes: "overflow-hidden pt-4 md:pt-16 mb-28",
                slider_classes:
                  "relative sm:translate-x-[115px] z-20 px-4 md:px-8",
                slide_classes: "",
                crew_members:
                  props.allWpPage.edges[0].node.crew.department[1].crewMembers,
                slides_to_show: 5,
                arrow_type: "smoke",
                draggable: true,
                response: [
                  {
                    breakpoint: 1200,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 2,
                      infinite: true,
                      dots: false,
                    },
                  },
                  {
                    breakpoint: 900,
                    settings: {
                      slidesToShow: 2,
                    },
                  },
                ],
              }}
            />
          </section>

          <section id="demand-generation" className="relative mt-5">
            <div className="bg-iron relative z-10">
              <div className="max-w-screen-xl mx-auto grid md:grid-cols-2 px-8 pt-20 sm:pb-10 md:px-20">
                <H2
                  title={props.allWpPage.edges[0].node.crew.department[2].title}
                  classes={"mb-4 lg:mb-0"}
                />
                <Paragraph
                  content={
                    props.allWpPage.edges[0].node.crew.department[2].description
                  }
                  classes={"text-smoke"}
                />
              </div>
            </div>
            <div className="bg-iron w-full absolute top-[280px] sm:top-[250px] h-[200px] left-0"></div>
            <CrewSlider
              data={{
                container_classes: "overflow-hidden pt-16 mb-36",
                slider_classes:
                  "relative z-20 sm:translate-x-[115px] px-4 md:px-8",
                crew_members:
                  props.allWpPage.edges[0].node.crew.department[2].crewMembers,
                slides_to_show: 5,
                arrow_type: "smoke",
                draggable: true,
                response: [
                  {
                    breakpoint: 1200,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 2,
                      infinite: true,
                      dots: false,
                    },
                  },
                  {
                    breakpoint: 900,
                    settings: {
                      slidesToShow: 2,
                    },
                  },
                ],
              }}
            />
          </section>

          <section
            id="marketing-operations"
            className="relative mt-5 md:mt-10 mb-20 lg:mb-40"
          >
            <div className="relative z-10">
              <div className="max-w-screen-xl mx-auto grid md:grid-cols-2 px-8 md:px-20 pb-10 items-center">
                <H2
                  title={props.allWpPage.edges[0].node.crew.department[3].title}
                  classes={"mb-4 lg:mb-0"}
                />
                <Paragraph
                  content={
                    props.allWpPage.edges[0].node.crew.department[3].description
                  }
                  classes={"text-smoke"}
                />
              </div>
            </div>
            <div className="max-w-screen-xl mx-auto">
              <CrewSlider
                data={{
                  container_classes:
                    "max-w-screen-sm mx-auto xl:mx-0 xl:ml-auto sm:flex sm:justify-center xl:block mt-4",
                  slider_classes:
                    "relative z-20 -translate-y-28 md:-translate-y-40 px-4 md:px-0",
                  crew_members:
                    props.allWpPage.edges[0].node.crew.department[3]
                      .crewMembers,
                  slides_to_show: 2,
                  arrow_type: "none",
                  response: [
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: false,
                      },
                    },
                  ],
                }}
              />
            </div>
          </section>

          <section id="technology" className="relative mt-5">
            <div className="bg-iron relative z-10">
              <div className="max-w-screen-xl mx-auto grid md:grid-cols-2 px-8 pt-20 pb-32 md:pb-40 md:px-20">
                <H2
                  title={props.allWpPage.edges[0].node.crew.department[4].title}
                  classes={"mb-4 lg:mb-0"}
                />
                <Paragraph
                  content={
                    props.allWpPage.edges[0].node.crew.department[4].description
                  }
                  classes={"text-smoke"}
                />
              </div>
            </div>
            <div className="max-w-screen-xl mx-auto">
              <CrewSlider
                data={{
                  container_classes:
                    "mt-12 md:mt-20 max-w-screen-sm mx-auto xl:mx-0 xl:ml-auto",
                  slider_classes:
                    "relative z-20 -translate-y-28 md:-translate-y-40 px-4 md:px-0",
                  crew_members:
                    props.allWpPage.edges[0].node.crew.department[4]
                      .crewMembers,
                  slides_to_show: 2,
                  arrow_type: "none",
                  response: [
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: false,
                      },
                    },
                  ],
                }}
              />
            </div>
          </section>

          <section id="creative" className="max-w-screen-xl mx-auto mt-5">
            <div className="grid md:grid-cols-2 px-8 pb-32 md:pb-40 md:px-20">
              <H2
                title={props.allWpPage.edges[0].node.crew.department[5].title}
                classes={"mb-4 lg:mb-0"}
              />
              <Paragraph
                content={
                  props.allWpPage.edges[0].node.crew.department[5].description
                }
                classes={"text-smoke"}
              />
            </div>
            <div className="max-w-screen-xl mx-auto">
              <CrewSlider
                data={{
                  container_classes: "mt-12 md:mt-20",
                  slider_classes:
                    "relative z-20 -translate-y-28 md:-translate-y-40 px-4 md:px-8",
                  crew_members:
                    props.allWpPage.edges[0].node.crew.department[5]
                      .crewMembers,
                  slides_to_show: 4,
                  arrow_type: "smoke",
                  draggable: true,
                  response: [
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 3,
                      },
                    },
                    {
                      breakpoint: 900,
                      settings: {
                        slidesToShow: 2,
                      },
                    },
                  ],
                }}
              />
            </div>
          </section>

          <section id="accounting" className="relative mt-5">
            <div className="bg-iron relative z-10">
              <div className="max-w-screen-xl mx-auto grid md:grid-cols-2 px-8 pt-20 pb-32 md:pb-32 md:px-20">
                <H2
                  title={props.allWpPage.edges[0].node.crew.department[6].title}
                  classes={"mb-4 lg:mb-0"}
                />
                <Paragraph
                  content={
                    props.allWpPage.edges[0].node.crew.department[6].description
                  }
                  classes={"text-smoke"}
                />
              </div>
            </div>
            <div className="max-w-screen-xl mx-auto">
              <CrewSlider
                data={{
                  container_classes:
                    "max-w-screen-sm mx-auto xl:mx-0 xl:ml-auto sm:flex sm:justify-center xl:block",
                  single_container_classes: "-translate-y-16 relative z-10",
                  slider_classes:
                    "relative z-20 -translate-y-28 md:-translate-y-40 px-4 md:px-0",
                  crew_members:
                    props.allWpPage.edges[0].node.crew.department[6]
                      .crewMembers,
                  slides_to_show: 2,
                  arrow_type: "none",
                  response: [
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: false,
                      },
                    },
                  ],
                }}
              />
            </div>
          </section>

          <section id="ghost-crew" className="relative mt-5">
            <div className="relative z-10">
              <div className="max-w-screen-xl mx-auto grid md:grid-cols-2 px-8 pt-20 pb-32 md:pb-40 md:px-20">
                <H2
                  title={props.allWpPage.edges[0].node.crew.department[7].title}
                  classes={"mb-4 lg:mb-0"}
                />
                <Paragraph
                  content={
                    props.allWpPage.edges[0].node.crew.department[7].description
                  }
                  classes={"text-smoke"}
                />
              </div>
            </div>
            <div className="max-w-screen-xl mx-auto">
              <CrewSlider
                data={{
                  container_classes:
                    "mt-12 md:mt-20 max-w-screen-sm mx-auto xl:mx-0 xl:ml-auto",
                  slider_classes:
                    "relative z-20 -translate-y-28 md:-translate-y-40 px-4 md:px-0",
                  crew_members:
                    props.allWpPage.edges[0].node.crew.department[7]
                      .crewMembers,
                  slides_to_show: 2,
                  arrow_type: "none",
                  response: [
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: false,
                      },
                    },
                  ],
                }}
              />
            </div>
          </section>

          <section
            id="office-dogs"
            className="opacity-0 transition-all duration-500 h-0 hidden sm:block"
          >
            <div className="max-w-screen-xl mx-auto mt-5 grid md:grid-cols-2 px-8 pb-14 md:px-20">
              <H2
                title={props.allWpPage.edges[0].node.crew.department[8].title}
                classes={"mb-4 lg:mb-0"}
              />
              <Paragraph
                content={
                  props.allWpPage.edges[0].node.crew.department[8].description
                }
                classes={"text-smoke"}
              />
            </div>
            <DogSlider
              data={{
                container_classes: "overflow-hidden pt-4 md:pt-16 mb-28",
                slider_classes:
                  "relative sm:translate-x-[115px] z-20 px-4 md:px-8",
                slide_classes: "",
                crew_members:
                  props.allWpPage.edges[0].node.crew.department[8].crewMembers,
                slides_to_show: 5,
                arrow_type: "smoke",
                draggable: true,
                response: [
                  {
                    breakpoint: 1200,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 900,
                    settings: {
                      slidesToShow: 2,
                    },
                  },
                ],
              }}
            />
          </section>

          <section
            className="max-w-screen-xl mx-auto relative z-10 mb-14 md:mb-32"
            id="by-the-numbers"
          >
            <div className="bg-bronze grid md:grid-cols-2 md:gap-x-32 px-8 py-20 md:px-20 md:py-28">
              <div>
                <H4
                  title={props.allWpPage.edges[0].node.crew.numbersSubtitle}
                  classes={"text-smoke"}
                />
                <H2
                  title={props.allWpPage.edges[0].node.crew.numbersTitle}
                  classes={"text-smoke"}
                />
                <Paragraph
                  content={
                    props.allWpPage.edges[0].node.crew.numbersDescription
                  }
                  classes={"text-ink mt-3 md:mt-7"}
                />
              </div>
              <div className="mt-12 md:mt-0">
                <ul className="grid text-center md:text-left lg:grid-cols-2 gap-14 sm:gap-10 lg:gap-14">
                  {props.allWpPage.edges[0].node.crew.number.map((card) => {
                    return (
                      <li key={card.title}>
                        {card.title.toLowerCase().includes("dog") ? (
                          <button
                            className="flex flex-col group items-center sm:items-start w-full sm:w-auto"
                            onClick={showDogSlider}
                          >
                            <p className="text-smoke text-lg font-bold rift uppercase leading-[18px]">
                              {card.title}
                            </p>
                            <p className="text-ink text-5xl gin-regular uppercase leading-[48px] transition-all duration-300   group-hover:text-smoke">
                              {card.statistic}
                            </p>
                          </button>
                        ) : (
                          <>
                            <p className="text-smoke text-lg font-bold rift uppercase leading-[18px]">
                              {card.title}
                            </p>
                            <p className="text-ink text-5xl gin-regular uppercase leading-[48px]">
                              {card.statistic}
                            </p>
                          </>
                        )}
                        <span className="text-ink text-lg font-bold rift uppercase leading-[18px]">
                          {card.details}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </section>

          <Prefooter
            title={
              props.allWpPage.edges[0].node.template.footerContent.footerTitle
            }
            description={
              props.allWpPage.edges[0].node.template.footerContent
                .footerDescription
            }
            background={
              props.allWpPage.edges[0].node.template.footerContent
                .footerBackground
            }
          />
        </MainLayout>
      )}
    />
  );
};

export default TheCrew;
