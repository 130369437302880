import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import useIsMobile from "../hooks/useIsMobile";

const Arrow = (props) => {
  const { onClick } = props;
  const arrowColor =
    props.color === "smoke"
      ? "bg-smoke group-hover:bg-bronze"
      : "bg-ink group-hover:bg-bronze";
  const arrowType =
    props.type === "next"
      ? [
          "left-[90px]",
          "-right-[9px] -rotate-45",
          "-right-[9px] rotate-45",
          "top-0 -right-[6px]",
        ]
      : [
          "left-[45px]",
          "-left-[2px] rotate-45",
          "-left-[2px] -rotate-45",
          "top-0 left-0",
        ];
  return (
    <button
      onClick={onClick}
      className={`absolute -top-7 group h-[25px] w-[25px] cursor-pointer ${arrowType[0]}`}
      aria-label={`Click to navigate through crew member profiles`}
    >
      <span
        className={`w-3 h-[1px] block absolute top-1 transition-all duration-400 ${arrowType[1]} ${arrowColor}`}
      ></span>
      <span
        className={`w-3 h-[1px] block absolute -top-1 transition-all duration-400 ${arrowType[2]} ${arrowColor}`}
      ></span>
      <span
        className={`w-8 h-[1px] block absolute transition-all duration-400 ${arrowType[3]} ${arrowColor}`}
      ></span>
    </button>
  );
};

export default function DogSlider({ data }) {
  const isMobile = useIsMobile(576);
  const [slidesShown, setSlidesShown] = useState(data.slides_to_show);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 576) {
        setSlidesShown(1);
      } else if (window.innerWidth < 800) {
        setSlidesShown(2);
      } else if (window.innerWidth < 1080) {
        setSlidesShown(3);
      } else {
        setSlidesShown(data.slides_to_show);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data.slides_to_show]);

  const settings = {
    draggable: data.draggable,
    dots: false,
    infinite: true,
    className: data.slider_classes,
    speed: 500,
    slidesToShow: slidesShown,
    slidesToScroll: 1,
    responsive: data.response,
    arrows: data.arrow_type === "none" ? false : true,
    nextArrow: <Arrow color={data.arrow_type} type={"next"} />,
    prevArrow: <Arrow color={data.arrow_type} type={"previous"} />,
  };
  return (
    <div className={`${data.container_classes}`}>
      {isMobile ? (
        <div className={`flex flex-col gap-y-14 ${data.slider_classes}`}>
          {data.crew_members.map((member) => {
            return (
              <div key={member.name} className="group px-4">
                <div
                  className="h-[400px] sm:h-[300px] bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url(${member.smallProfile.sourceUrl})`,
                  }}
                ></div>
                <p className="gin-regular text-smoke text-[28px] uppercase mt-4">
                  {member.name}
                </p>
                <p className="elza text-bronze text-sm font-light leading-normal tracking-tight">
                  {member.jobTitle}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <Slider {...settings}>
          {data.crew_members.map((member) => {
            return (
              <div key={member.name} className={`group px-3`}>
                <div
                  className={`h-[400px] sm:h-[300px] bg-cover bg-center bg-no-repeat`}
                  style={{
                    backgroundImage: `url(${member.smallProfile.sourceUrl})`,
                  }}
                ></div>
                <p
                  className={`gin-regular text-smoke text-[28px] uppercase mt-4 ${
                    member.name.length >= 20 ? "leading-tight" : ""
                  }`}
                >
                  {member.name}
                </p>
                <p className="elza text-bronze text-sm font-light leading-normal tracking-tight">
                  {member.jobTitle}
                </p>
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
}
